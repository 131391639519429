import { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import AWSIcon from 'svgs/single-case-study/aws.svg';
import MobileDevelopmentIcon from 'svgs/single-case-study/mobile-development.svg';
import ReactJsIcon from 'svgs/single-case-study/react-js.svg';
import UxUiDesign from 'svgs/single-case-study/ux-ui-design.svg';
import WebDevelopmentIcon from 'svgs/single-case-study/web-development.svg';

import { contentResolver } from '../utilities';

const paragraphsCounters = {
    header: 1,
    about: 1,
    challenge: 4,
    mainGoals: 4,
    solutions: 4,
    summary: 3,
};

const content = contentResolver({
    brandColor: '--tipsters-case-study',
    scopeOfWork: [
        {
            title: 'scopeOfWork.webDevelopment',
            Icon: WebDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.reactJs',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.mobileDevelopment',
            Icon: MobileDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiDesign,
        },
        {
            title: 'scopeOfWork.awsCloud',
            Icon: AWSIcon,
        },
    ],
    projectInfo: {
        count: 2,
        colors: ['--tipsters-case-study-red', '--tipsters-case-study-red'],
        dataIndexes: [0, 2],
    },
    headerWrapperCss: css`
        width: 100%;
    `,
    headerFirstCss: css`
        width: 80%;
    `,
    paragraphsCounters,
    caseStudies: ['nftReality', 'letempick', 'heyway'],
    caseStudiesSubtitle: 'case-studies.subtitle',
});

// Manually update alt text for images
content.headerImageAlt = 'sports betting tips software development';
content.about.alt = 'sports betting tips application';
content.challenge.alt = 'sports betting tips app';
content.mainGoals.alt =
    'sports betting tips software development app development';
content.solutions.alt = 'sports betting tips software';
content.summary.alt = 'sports betting software development';

export const useTipsters = () => {
    const images = useStaticQuery(graphql`
        query {
            headerImage: file(
                relativePath: { eq: "single-case-study/4tipsters/laptop.png" }
            ) {
                ...ImageCaseStudyConfig
            }
            aboutImage: file(
                relativePath: { eq: "single-case-study/4tipsters/about.png" }
            ) {
                ...ImageCaseStudyConfig
            }
            challengeImage: file(
                relativePath: {
                    eq: "single-case-study/4tipsters/challenge.png"
                }
            ) {
                ...ImageCaseStudyConfig
            }
            mainGoalsImage: file(
                relativePath: {
                    eq: "single-case-study/4tipsters/main-goals.png"
                }
            ) {
                ...ImageCaseStudyConfig
            }
            solutionsImage: file(
                relativePath: {
                    eq: "single-case-study/4tipsters/solutions.png"
                }
            ) {
                ...ImageCaseStudyConfig
            }
            summaryImage: file(
                relativePath: { eq: "single-case-study/4tipsters/summary.png" }
            ) {
                ...ImageCaseStudyConfig
            }
        }
    `);

    return {
        data: { ...images, ...content },
    };
};
